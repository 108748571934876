import { captureException } from '@sentry/react';

import Select from 'ol/interaction/Select';
import VectorLayer from 'ol/layer/Vector';
import Draw from 'ol/interaction/Draw';
import VectorSource from 'ol/source/Vector';
import booleanContains from '@turf/boolean-contains';
import booleanIntersects from '@turf/boolean-intersects';

import { GEOMETRY_TYPE, GEOMETRY_TYPE_STRING, LAYER_INDEX, MAP_LAYERS } from '../../../Constants/Constant';
import { layerTracker, outputMap } from '../MapInit';
import { TOOL_EVENT } from '../../Output/Toolbar/ToolController';
import { Observer } from '../../../Utils/Observer';
import { darkStroke, lightStroke } from '../../../Utils/olutils';
import { adjustOverlayPosition } from '../../../Utils/HelperFunctions';

class LassoReclassify extends Observer {
  draw: $TSFixMe;

  featureType: $TSFixMe;

  layer: $TSFixMe;

  mapObj: $TSFixMe;

  select: $TSFixMe;

  constructor(mapObj: $TSFixMe) {
    super();
    this.mapObj = mapObj;
    this.select = null;
    this.layer = null;
    this.featureType = null;
  }

  /**
   * On LassoReclassify tool
   * @param {Array} features
   * @param {Int} featureType
   */
  on({ featureType = null }) {
    this.off();
    this.featureType = featureType;

    const src = new VectorSource({ wrapX: false });
    this.layer = new VectorLayer({
      // @ts-expect-error TS(2345): Argument of type '{ id: string; source: VectorSour... Remove this comment to see the full error message
      id: 'empty-draw-layer',
      source: src,
      style: [lightStroke, darkStroke],
      zIndex: LAYER_INDEX.DRAW
    });
    this.mapObj.addLayer(this.layer);

    this.draw = new Draw({
      type: GEOMETRY_TYPE_STRING.POLYGON,
      source: src,
      style: [lightStroke, darkStroke],
      condition: e => {
        const mouseClick = e.originalEvent.button;
        if (mouseClick === 2 || mouseClick === 1) {
          return false;
        }
        return true;
      },
      snapTolerance: 1,
      ...(this.mapObj.enableRightClickDrag && { dragVertexDelay: 0 })
    });
    this.mapObj.map.addInteraction(this.draw);
    this.draw.on('drawstart', this.handleDrawStart);
    this.draw.on('drawend', this.handleDrawEnd);

    this.select = new Select({
      // @ts-expect-error TS(2345): Argument of type '{ wrapX: boolean; filter: () => ... Remove this comment to see the full error message
      wrapX: false,
      filter: () => false,
      condition: () => false
    });
    this.mapObj.map.addInteraction(this.select);
    document.addEventListener('keydown', this.removeLastPointOnBack);
  }

  /**
   * Remove last added point on pressing backspace
   * @param {Event} event
   */
  removeLastPointOnBack = (event: $TSFixMe) => {
    if (event.stopPropagation) event.stopPropagation();

    const KeyID = event.keyCode;
    if (KeyID === 8) {
      this.draw.removeLastPoint();
    }
    if (KeyID === 27) {
      this.draw.abortDrawing();
    }
  };

  /**
   * Clear the drawing on map and remove all selected features
   */
  clearDrawing() {
    this.layer?.getSource()?.clear();
    this.select?.getFeatures()?.clear();
    const container = document.getElementById('lasso-reclassifier-container');
    if (container) {
      container.style.display = 'none';
    }
  }

  handleDrawStart = () => {
    this.clearDrawing();
  };

  /**
   * Loop through the features which are in viewport
   * Find contained or intersected features from drawn line
   * @param {Event} e
   */
  handleDrawEnd = (e: $TSFixMe) => {
    const { feature } = e;
    const drawnFeatureGeojson = this.getGeojsonByFeature(feature);

    const allMapLayers = this.mapObj.getLayers();

    allMapLayers.forEach((layer: $TSFixMe) => {
      const isVisible = layer.getVisible();
      const lyrName = layer?.get('name');

      if (isVisible && lyrName === MAP_LAYERS.OUTPUT) {
        /**
         * const considerLyr = this.featureType
         * ? lyrName === MAP_LAYERS.OUTPUT
         * : !deleteNonHighLightLayers(lyrName) && layer?.get('id') !== BASE_LAYER_ID;
         * if (isVisible && considerLyr)
         */

        try {
          const layerSource = layer.getSource();
          // Loop only on features which are available in the viewport
          layerSource.forEachFeature((outputLayerFeature: $TSFixMe) => {
            const outputLayerFeatureGeojson = this.getGeojsonByFeature(outputLayerFeature);
            // fix error when polygon feature have empty/no coordinates
            if (!outputLayerFeatureGeojson?.geometry?.coordinates?.length) {
              return;
            }
            const isContain = booleanContains(drawnFeatureGeojson, outputLayerFeatureGeojson);
            const isIntersect = booleanIntersects(drawnFeatureGeojson, outputLayerFeatureGeojson);
            if (isContain || isIntersect) {
              this.select.getFeatures().push(outputLayerFeature);
            }
          });
        } catch (err) {
          captureException(err);
        }
      }
    });

    if (this.featureType == null) {
      this.deleteSelectedFeatures();
    }

    // If selected operation is reclassify then filter out selected geometry type and show features dropdown
    if (this.featureType !== null) {
      const selectedFeatures = this.select.getFeatures().getArray();
      const filteredFeatures = selectedFeatures.filter(
        (f: $TSFixMe) => f.getGeometry().getType() === GEOMETRY_TYPE[this.featureType]
      );
      this.select.getFeatures().clear();
      if (filteredFeatures.length) {
        filteredFeatures.forEach((f: $TSFixMe) => {
          this.select.getFeatures().push(f);
        });
      }

      this.notifyObservers(TOOL_EVENT.LASSO_TOOL_GEOMETRY, GEOMETRY_TYPE[this.featureType]);

      // @ts-expect-error TS(2531): Object is possibly 'null'.
      const mapBox = document.getElementById('map').getBoundingClientRect();
      const container = document.getElementById('lasso-reclassifier-container');
      // @ts-expect-error TS(2531): Object is possibly 'null'.
      container.style.display = 'block';

      const { offsetWidth: overlayWidth, offsetHeight: overlayHeight } = container || {};
      const pageX = mapBox.left + e.target.downPx_[0] + 20;
      const pageY = mapBox.top + e.target.downPx_[1] + 20;

      const [positionX, positionY] = adjustOverlayPosition({
        pageX,
        pageY,
        overlayWidth,
        overlayHeight
      });

      // @ts-expect-error TS(2531): Object is possibly 'null'.
      container.style.left = `${parseInt(positionX, 10)}px`;
      // @ts-expect-error TS(2531): Object is possibly 'null'.
      container.style.top = `${parseInt(positionY, 10)}px`;
    }
  };

  /**
   * Delete selected features from the map
   */
  deleteSelectedFeatures() {
    const features = this.select.getFeatures().getArray();
    features?.length &&
      features.forEach((feature: $TSFixMe) => {
        const layerId = feature.get('layerId');
        const layer = this.mapObj.getLayerById(layerId);

        /**
         * will be implemented after multilayer modification
         * const layer = this.mapObj.getLayerById(feature?.get('isLabel') ? MAP_LAYERS.LABELS : layerId);
         */

        if (layer) {
          layerTracker.push(layer.get('name'), layerId);

          layer.getSource().removeFeature(feature);
        }
      });
    this.layer.setSource(new VectorSource({ wrapX: false }));

    layerTracker.getArray().length && this.notifyObservers(TOOL_EVENT.LASSO_TOOL);
  }

  /**
   * Reclassify selected features from the map
   * @param {String} targetLayerName
   */
  reclassifySelectedFeatures(targetLayerName: $TSFixMe) {
    const features = this.select.getFeatures();
    const targetLayer = this.mapObj.getLayerById(targetLayerName);
    features.forEach((feature: $TSFixMe) => {
      if (targetLayer) {
        const targetLayerId = targetLayer.get('id');
        const { layerId } = feature.getProperties();
        const layer = this.mapObj.getLayerById(layerId);

        if (feature && layer && layerId !== targetLayerId) {
          feature.setProperties({ layerId: targetLayerId });
          targetLayer.getSource().addFeature(feature);

          layer.getSource().removeFeature(feature);

          layerTracker.push(layer.get('name'), layerId);
          layerTracker.push(targetLayer.get('name'), targetLayerId);
        }
      }
    });
    this.clearDrawing();

    this.notifyObservers(TOOL_EVENT.LASSO_TOOL);
  }

  /**
   * Get geojson from openlayers feature
   * @param {Feature} feature
   * @returns Geojson
   */
  getGeojsonByFeature(feature: $TSFixMe) {
    return outputMap.getGeojsonByFeature(feature);
  }

  /**
   * Off the tool
   */
  off() {
    this.clearDrawing();
    this.featureType = null;
    this.mapObj.map.removeInteraction(this.select);
    this.mapObj.removeLayer(this.layer);
    this.mapObj.map.removeInteraction(this.draw);
    document.removeEventListener('keydown', this.removeLastPointOnBack);
  }
}

export default LassoReclassify;
